import React from "react";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import Navbar from "../layout/header/Navbar";

export default function TermsAndConditions() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Terms and Conditions" />

      <div className="terms-conditions container py-5">
        <p>Last updated: March 30, 2024</p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using this website, you accept and agree to be bound
          by the terms and provisions of this agreement. If you do not agree to
          abide by the above, please do not use this service.
        </p>

        <h2>2. Changes to Terms</h2>
        <p>
          We reserve the right to modify or replace these Terms at any time.
          Your continued use of the site after any such changes constitutes
          acceptance of the new Terms.
        </p>

        <h2>3. Use of the Site</h2>
        <p>
          You agree to use the website only for lawful purposes and in a way
          that does not infringe on the rights of, restrict or inhibit anyone
          else's use and enjoyment of the site.
        </p>

        <h2>4. Intellectual Property</h2>
        <p>
          All content, trademarks, and data on this website, including but not
          limited to text, graphics, logos, and software, are the property of
          their respective owners and are protected by applicable intellectual
          property laws.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          We are not liable for any damages that may occur to you as a result of
          your use of this website, to the fullest extent permitted by law.
        </p>

        <h2>6. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
          <br />
          <a href="mailto:contactus@schemesbook.com">
            contactus@schemesbook.com
          </a>
        </p>
      </div>
    </Layout>
  );
}
